
import './App.css';
import PortfolioPage from './PortfolioPage';

function App() {
  return (
    <div>
      <PortfolioPage />
    </div>
  );
}

export default App;
